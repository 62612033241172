<template>
  <div class="container-page them-table" v-loading="loading">
    <!-- 搜索区域 -->
    <el-form
      inline
      :model="searchForm"
      class="flex ai-center"
      @submit.native.prevent
      style="
        border-bottom: none;
        background: #f9f9f9;
        padding: 0.3rem 0.6rem;
        border: 0.5px solid #ebeef5;
      "
    >
      <el-form-item label="部门名称" style="margin-bottom: 0; width: 12.6rem">
        <el-autocomplete
          clearable
          size="small"
          style="width: 8rem"
          placeholder="请输入内容"
          v-model="searchForm.name"
          :fetch-suggestions="queryNameAsync"
        ></el-autocomplete>
      </el-form-item>

      <el-form-item label="城市" style="margin-bottom: 0; width: 10.8rem">
        <el-autocomplete
          clearable
          size="small"
          style="width: 8rem"
          placeholder="请输入内容"
          v-model="searchForm.cityValue"
          :fetch-suggestions="queryCityAsync"
        ></el-autocomplete>
      </el-form-item>

      <el-form-item label="门店编码" style="margin-bottom: 0; width: 12.6rem">
        <el-input
          clearable
          size="small"
          placeholder="全部"
          style="width: 8rem"
          v-model="searchForm.customCode"
          @keyup.enter.native="departmentList"
        ></el-input>
      </el-form-item>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-search"
        @click="departmentList"
        >查询</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd()"
        >添加</el-button
      >
      <el-button size="small" type="primary" @click="expandAll(true)"
        >展开全部</el-button
      >
      <el-button size="small" type="primary" @click="expandAll(false)"
        >折叠全部</el-button
      >
    </el-form>

    <!-- 拖拽表格 -->
    <myTable
      @handleEdit="handleEdit"
      v-if="showTable && $store.state.organize.dataArray.length"
    ></myTable>

    <!-- 添加弹窗 -->
    <addDialog
      v-if="showDialog"
      @close="hideDialog"
      :editData="editData"
      :cityList="cityList"
    ></addDialog>
  </div>
</template>

<script>
import * as _ from 'lodash'
import addDialog from './components/addDialog.vue'
import myTable from './components/myTable.vue'
export default {
  components: {
    addDialog,
    myTable,
  },
  data() {
    return {
      loading: false,

      cityList: [],
      queryCityList: [],
      queryDepartmentList: [],

      // 搜索表单
      searchForm: {
        name: '',
        cityValue: '',
        customCode: '',
      },
      // 显示table表格
      showTable: true,
      // 是否显示弹窗
      showDialog: false,
      editData: null,
    }
  },
  async created() {
    this.loading = true
    // 城市列表，用于筛选
    await this.getCityList()
    // 部门列表，用来渲染表格
    await this.departmentList()
    this.loading = false
  },
  methods: {
    // 根据部门名称筛选
    queryNameAsync(queryString, cb) {
      var queryDepartmentList = this.queryDepartmentList
      var results = queryString
        ? queryDepartmentList.filter(this.createStateFilter(queryString))
        : queryDepartmentList

      cb(results)
    },
    // 根据城市筛选
    queryCityAsync(queryString, cb) {
      var queryCityList = this.queryCityList
      var results = queryString
        ? queryCityList.filter(this.createStateFilter(queryString))
        : queryCityList

      cb(results)
    },
    createStateFilter(queryString) {
      return (item) => {
        return item.value.indexOf(queryString) === 0
      }
    },

    // 获取城市列表
    async getCityList() {
      this.cityList = []
      const res = await this.$api.getDictList({
        dictType: 'city',
      })

      if (res !== 'fail&&error') {
        this.cityList = res

        const result = []
        this.cityList.forEach((city) => {
          result.push({
            value: city.dictValue,
          })
        })
        this.queryCityList = result
      }
    },

    // 获取部门列表
    async departmentList() {
      this.$store.commit('SET_DATA_ARRAY', [])

      const res = await this.$api.departmentList(this.searchForm)

      // 如果接口调用成功 && 返回结果不为空
      if (res !== 'fail&&error' && res.length) {
        this.$store.commit(
          'SET_DATA_ARRAY',
          this.assemblyData(_.cloneDeep(res))
        )

        // 数据扁平化 && 赋值queryDepartmentList
        const queryDepartmentList = this.getQueryDepartmentList(
          _.cloneDeep(res)
        )
        if (queryDepartmentList.length > this.queryDepartmentList.length) {
          this.queryDepartmentList = queryDepartmentList
        }
      }
    },
    // 递归设置默认不展示子菜单
    assemblyData(dataArray, level = 1, showChildren = false) {
      // if (level === 1) {
      //   console.log('dataArray', dataArray)
      // }

      dataArray.forEach((item) => {
        // 这里如果item已经是最后一级的话，没有children属性
        item.level = level
        item.showChildren = showChildren

        if (item.children && item.children.length) {
          this.assemblyData(item.children, level + 1, showChildren)
        }
      })

      return dataArray
    },
    // 获取用于筛选的部门名称列表
    getQueryDepartmentList(dataArray) {
      let result = []
      dataArray.forEach((item) => {
        result = [
          ...result,
          {
            value: item.name,
          },
        ]

        if (item.children && item.children.length) {
          result = [...result, ...this.getQueryDepartmentList(item.children)]
        }
      })
      return result
    },
    // 展开or折叠全部
    expandAll(bool) {
      this.$store.commit(
        'SET_DATA_ARRAY',
        this.assemblyData(this.$store.state.organize.dataArray, 1, bool)
      )

      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
    // 编辑部门
    // row：当前部门的数据
    handleEdit(row) {
      this.editData = { ...row }
      this.showDialog = true
    },
    // 添加部门
    handleAdd() {
      this.editData = {
        type: 0,
      }
      this.showDialog = true
    },
    // 隐藏弹窗
    hideDialog(param) {
      this.showDialog = false
      if (param === 'reload') {
        this.departmentList()
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
