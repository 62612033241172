<template>
  <el-dialog
    top="3vh"
    width="38rem"
    :visible="true"
    :show-close="false"
    custom-class="them-dialog"
    v-loading="$store.state.requestLoading"
  >
    <div slot="title">
      {{ formData.departmentId ? '修改部门' : '添加部门' }}
    </div>
    <el-form :model="formData" ref="formData" label-width="8rem" :rules="rules">
      <el-form-item label="部门名称:" prop="name">
        <el-input
          clearable
          v-model="formData.name"
          placeholder="请输入部门名称"
          style="width: 16rem; margin-right: 1rem"
        ></el-input>
        <el-checkbox
          v-model="isStore"
          :disabled="formData.children && formData.children.length > 0"
          >是否门店</el-checkbox
        >
      </el-form-item>

      <el-form-item label="电话:" prop="mobile" v-if="isStore">
        <el-input
          clearable
          style="width: 16rem"
          placeholder="请输入电话"
          v-model="formData.mobile"
        ></el-input
      ></el-form-item>

      <el-form-item label="地址:" prop="address" v-if="isStore">
        <el-input
          clearable
          style="width: 16rem"
          placeholder="请输入地址"
          v-model="formData.address"
        ></el-input>
      </el-form-item>

      <el-form-item label="门店编码:" prop="customCode" v-if="isStore">
        <el-input
          clearable
          maxlength="10"
          style="width: 16rem"
          placeholder="请输入门店编码"
          v-model="formData.customCode"
        ></el-input>
      </el-form-item>

      <el-form-item label="城市:">
        <el-select
          clearable
          style="width: 16rem"
          placeholder="请选择城市"
          v-model="formData.cityCode"
        >
          <el-option
            :key="item.dictCode"
            :value="item.dictCode"
            :label="item.dictValue"
            v-for="item in cityList"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否支持试用:">
        <el-checkbox v-model="formData.supportProbation">是</el-checkbox>
      </el-form-item>

      <el-form-item label="备注:" prop="memo">
        <el-input type="textarea" rows="6" v-model="formData.memo"></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    editData: {
      type: Object,
      default: null
    },
    cityList: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isStore: true, // 是否门店
      formData: {
        name: '',
        type: 0,
        cityCode: '', // 只有type===1时，才有此字段
        mobile: '',
        address: '',
        memo: '',
        supportProbation: 0, // 是否支持试用
      },
      rules: {
        name: [
          {
            required: true,
            whitespace: true,
            message: '请输入部门名称',
            trigger: ['blur', 'change']
          },
          {
            validator: this.$util.validateFeildLength(10),
            trigger: ['blur', 'change']
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入电话号码',
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              if (!/^\d{4,30}$/.test(value)) {
                return callback(new Error('请输入正确的电话号码'))
              }
              callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        address: [
          {
            required: true,
            whitespace: true,
            message: '请输入地址',
            trigger: ['blur', 'change']
          },
          {
            validator: this.$util.validateFeildLength(100),
            trigger: ['blur', 'change']
          }
        ],
        customCode: [
          {
            required: true,
            message: '请输入门店编码',
            trigger: ['blur', 'change']
          }
        ],
        memo: [
          {
            validator: this.$util.validateFeildLength(100),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  created() {
    this.editData && (this.formData = { ...this.formData, ...this.editData })
    this.isStore = this.editData.type === 1 // 是否门店
    this.formData.supportProbation = this.formData.supportProbation === 1
  },
  methods: {
    submit() {
      //  1.校验数据
      this.$refs.formData.validate(async (valid) => {
        // 1.1.验证通过
        if (valid) {
          // 2.组装数据
          this.formData.supportProbation = this.formData.supportProbation ? 1 : 0
          this.formData.type = this.isStore ? 1 : 0
          if (this.formData.type === 0) {
            // 非门店
            this.formData.mobile = ''
            this.formData.address = ''
          }
          // 3.发送请求
          let res = null
          if (this.formData.departmentId) {
            // 修改
            res = await this.$api.departmentUpdate(this.formData)
          } else {
            // 添加
            res = await this.$api.departmentSave(this.formData)
          }
          if (res !== 'fail&&error') {
            // 4.关闭弹窗
            // this.$confirm(this.formData.departmentId ? '修改成功' : '添加成功', '提示', {
            //   confirmButtonText: '确定',
            //   cancelButtonText: '取消',
            //   type: 'success'
            // })
            //   .then(() => {})
            //   .catch(() => {})
            this.$emit('close', 'reload')
          } else {
            // this.$confirm(this.formData.departmentId ? '修改失败' : '添加失败', '提示', {
            //   confirmButtonText: '确定',
            //   cancelButtonText: '取消',
            //   type: 'error'
            // })
            //   .then(() => {})
            //   .catch(() => {})
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
