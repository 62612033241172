<template>
  <div class="my-table-area" v-loading="$store.state.requestLoading">
    <!-- 表格头 -->
    <div class="tr th">
      <div class="td" style="width: 200px">部门名称</div>
      <div class="td flex-item">是否门店</div>
      <div class="td flex-item">门店编码</div>
      <div class="td flex-item">城市</div>
      <div class="td flex-item">电话</div>
      <div class="td flex-item">地址</div>
      <!-- <div class="td flex-item">sort</div> -->
      <div class="td flex-item">备注</div>
      <div class="td flex-item">创建时间</div>
      <div class="td flex-item">更新时间</div>
      <div class="td" style="width: 9.38rem"></div>
    </div>

    <!-- 拖拽表格 -->
    <mySubTable
      @handleEdit="handleEdit"
      :dataArray="$store.state.organize.dataArray"
    ></mySubTable>
  </div>
</template>

<script>
import _ from 'lodash'
import mySubTable from './mySubTable/mySubTable.vue'

export default {
  components: {
    mySubTable,
  },
  data() {
    return {}
  },
  methods: {
    handleEdit(parent) {
      this.$emit('handleEdit', parent)
    },
  },
}
</script>

<style lang="scss" scoped>
.my-table-area {
  ::v-deep .tr {
    display: flex;
    padding: 1rem 0;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebeef5;

    position: relative;

    // 拖拽的元素样式
    &.dragstart {
      border: 3px dashed rgba(54, 138, 216, 0.8);
      background-color: rgba(54, 138, 216, 0.2);

      * {
        pointer-events: none;
      }
      .dropbox {
        pointer-events: auto;
      }
    }

    .dragover {
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 100%;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      background-color: rgba(44, 160, 189, 0.2);

      & > div {
        flex: 1;
        display: flex;
        font-size: 13px;
        text-indent: 2em;
        align-items: center;
        & + div {
          border-top: 1px dashed rgba(0, 0, 0, 0.2);
        }
      }
    }

    &.dragover {
      .dragover {
        height: 100%;
      }

      * {
        pointer-events: none;
      }
      .dropbox {
        pointer-events: auto;
      }
    }

    &.th {
      font-weight: bold;
    }
    .td {
      line-height: 1.7;
      color: #606266;
      font-size: 0.88rem;
      padding-left: 1rem;

      display: flex;
      overflow: auto;
      align-items: center;

      // 折叠箭头
      .arrow {
        width: 1rem;
        cursor: pointer;
        font-size: 0.75rem;
        display: inline-block;
      }
      // 鼠标放到名字上
      .mover {
        cursor: move;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .level {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.level1 {
        width: 168px;
      }
      &.level2 {
        width: 136px;
      }
      &.level3 {
        width: 104px;
      }
      &.level4 {
        width: 72px;
      }
      &.level5 {
        width: 56px;
      }
    }
  }
}
</style>
