import * as _ from 'lodash'
export default {
  name: 'mySubTable',
  props: {
    dataArray: Array,
  },
  data() {
    return {}
  },
  methods: {
    dragstart(ev) {
      if (this.$store.state.organize.isDraging) return
      this.$store.commit('SET_IS_DRAGING', true)
      // 赋予拖拽样式
      ev.target.className = 'tr dragstart'

      // 存储拖拽的部门id
      const dragPid = ev.target.getAttribute('dragPid')
      const dragDepartmentId = ev.target.getAttribute('dragDepartmentId')

      if (!dragDepartmentId || !dragPid) return

      // 存储拖拽相关信息
      this.$store.commit('SET_DRAG_INFO', {
        dragPid,
        dragDepartmentId,
      })

      console.error('start...', dragDepartmentId, dragPid)

      // 隐藏当前拖拽的部门的子部门
      this.hiddenDragElementChild(
        _.cloneDeep(this.$store.state.organize.dataArray),
        dragDepartmentId
      )
    },
    dragend() {
      console.log('ondragend.......')
      this.$store.commit('SET_IS_DRAGING', false)

      // 恢复原样式，最粗暴的方式
      this.changeClassNameByClass('.tr', 'tr draggable')
    },
    dragenter(ev) {
      const type = ev.target.getAttribute('type')

      if (ev.target.className === 'tr draggable') {
        // 其他高亮的回复原状
        this.changeClassNameByClass('.tr.dragover', 'tr draggable')

        // 设置当前高亮行
        ev.target.className = 'tr dragover'
      } else if (type === 'top') {
        this.setHightLightAndInnerHTML(ev.target, '插入到此部门之前')
      } else if (type === 'middle') {
        this.setHightLightAndInnerHTML(ev.target, '插入到此部门的子部门')
      } else if (type === 'bottom') {
        this.setHightLightAndInnerHTML(ev.target, '插入到此部门之后')
      } else if (ev.target.className === 'tr dragstart') {
        this.clearDropBoxAll()

        // 其他高亮的回复原状
        this.changeClassNameByClass('.tr.dragover', 'tr draggable')
      }
    },
    dragover(ev) {
      ev.preventDefault()
    },
    async drop(ev) {
      console.log('ondrop')
      if (!this.$store.state.organize.isDraging) return
      this.$store.commit('SET_IS_DRAGING', false)

      if (!this.$store.state.organize.dragDepartmentId) {
        console.error('未能捕获拖拽部门ID')
        return
      }

      const type = ev.target.getAttribute('type')
      const targetPid = ev.target.getAttribute('targetPid')
      const targetChildrenLength = ev.target.getAttribute(
        'targetChildrenLength'
      )
      const targetDepartmentId = ev.target.getAttribute('targetDepartmentId')

      if (!targetDepartmentId) {
        console.error('未能捕获目标部门ID')
        return
      }

      // 代码走到这里，说明需要调用接口进行层级变更或者同级排序
      // 逻辑写到下面这个方法中
      this.changeDataArray(
        type,
        targetDepartmentId,
        targetPid,
        targetChildrenLength
      )
    },
    /**
     * 改变数据层级结构
     * @param {string} type : top|middle|bottom
     * @param {string} targetDepartmentId ：目标部门的部门id
     * @param {string} targetPid ：目标部门的上级部门id
     * @param {string} targetChildrenLength ：目标部门子部门的个数
     * @returns void
     */
    async changeDataArray(
      type,
      targetDepartmentId,
      targetPid,
      targetChildrenLength
    ) {
      // 无论type是top、middle还是bottom，先变更部门层级，再同级排序

      let isNext = false

      // 层级变更
      if (type === 'middle') {
        isNext = await this.changeDepartmentLevel(
          targetDepartmentId,
          this.$store.state.organize.dragDepartmentId
        )
      } else {
        isNext = await this.changeDepartmentLevel(
          targetPid,
          this.$store.state.organize.dragDepartmentId
        )
      }

      if (!isNext) return

      // 同级排序逻辑

      // 同级排序接口参数
      let postData = null

      // 如果是middle，则插入为第0个元素
      // 相当于调用排序接口，并设置nextDepartmentId为第一个部门的部门id
      // 如果子部门为空，则不需要调用排序接口
      if (type === 'middle') {
        if (targetChildrenLength > 0) {
          postData = this.getPostDataByIdAndType(
            this.$store.state.organize.dataArray,
            targetDepartmentId,
            type
          )
        } else {
          // 这里不用考虑，无需调用接口
        }
      } else {
        // 需要根据目标部门的id找到相邻的兄弟部门的id
        postData = this.getPostDataByIdAndType(
          this.$store.state.organize.dataArray,
          targetDepartmentId,
          type
        )
      }

      console.error('postData', postData)

      if (postData) {
        this.changeDepartmentSort(postData)
      }
    },
    // 层级变更
    async changeDepartmentLevel(pid, departmentId) {
      if (pid === this.$store.state.organize.dragPid) {
        // this.$message('层级未变')
        return true
      }
      const res = await this.$api.changeHierarchy({
        pid,
        departmentId,
      })

      // 如果接口调用失败，直接返回，中断后续操作
      if (res !== null) return false

      this.$message.success('层级变更成功')

      // 不刷新页面更新表格数据，前端控制展示，需要2步
      // 1，找到拖拽的部门，并从数据源中删除
      let { dataArray, dragObj } = this.getDeleteObjAndDataArray(
        _.cloneDeep(this.$store.state.organize.dataArray),
        departmentId
      )

      // 2，找到父部门，并把拖拽部门插入，插入时需更新level、更新pid和排序
      dataArray = this.updateDataArray(_.cloneDeep(dataArray), pid, dragObj[0])
      // 2.1，更新数据
      this.$store.commit('SET_DATA_ARRAY', dataArray)

      return true
    },
    // 同级排序-调用接口
    async changeDepartmentSort(postData) {
      if (!postData.previousDepartmentId && !postData.nextDepartmentId) return
      if (
        [postData.nextDepartmentId, postData.previousDepartmentId].includes(
          postData.middleDepartmentId
        )
      ) {
        // this.$message('顺序未做更改')
        return
      }

      const res = await this.$api.departmentSort(postData)
      if (res === null) {
        this.$message.success('顺序更改成功')

        // 不刷新更新列表展示，要做到的话，分下面几步
        // 1，从云端拿到最新数据
        // 2，找到拖拽的部门的新序号
        // 3，排序并更新数据源
        this.setDragDepartmentSort(postData.middleDepartmentId)
      }
    },
    // 同级排序-前端展示
    async setDragDepartmentSort(dragDepartmentId) {
      // 1，获取最新数据
      const newDataArray = await this.$api.departmentList()
      if (newDataArray === 'fail&&error' || !newDataArray.length) {
        return
      }

      // 2，获取拖拽部门最新序号，有云端获取
      const sort = this.getSortByID(newDataArray, dragDepartmentId)

      // 3，更新数据源
      // 3.1，用最新序号，替换老序号，并从新排序
      const dataArray = this.updateDepartmentSort(
        _.cloneDeep(this.$store.state.organize.dataArray),
        dragDepartmentId,
        sort
      )

      // 3.2，更新
      this.$store.commit('SET_DATA_ARRAY', dataArray)
    },
    // 获取部门序号根据部门id
    getSortByID(dataArray, departmentId) {
      for (let i = 0, len = dataArray.length; i < len; i++) {
        if (departmentId === dataArray[i].departmentId) {
          return dataArray[i].sort
        } else if (dataArray[i].children && dataArray[i].children.length) {
          const result = this.getSortByID(dataArray[i].children, departmentId)
          if (Object.prototype.toString.call(result) === '[object Number]') {
            return result
          }
        }
      }
    },
    // 更新序号并排序
    updateDepartmentSort(dataArray, departmentId, sort) {
      for (let i = 0, len = dataArray.length; i < len; i++) {
        if (departmentId === dataArray[i].departmentId) {
          // 更新拖拽部门的最新sort，从云端获取
          dataArray[i].sort = sort

          // 把拖拽部门放到应该在的位置
          dataArray.sort((a, b) => b.sort - a.sort)
          break
        } else if (dataArray[i].children && dataArray[i].children.length) {
          this.updateDepartmentSort(dataArray[i].children, departmentId, sort)
        }
      }

      return dataArray
    },
    // 根据目标部门id找兄弟部门id
    getPostDataByIdAndType(dataArray, targetDepartmentId, type) {
      for (let i = 0, len = dataArray.length; i < len; i++) {
        // 如果目标部门id在此次循环列表中
        if (targetDepartmentId === dataArray[i].departmentId) {
          const result = {
            middleDepartmentId: this.$store.state.organize.dragDepartmentId, // 拖拽的部门id
            nextDepartmentId: '', // 相邻兄弟部门（下一个）的部门id
            previousDepartmentId: '', // 相邻兄弟部门（上一个）的部门id
          }

          // middle的话，目标是父部门，找他的孩子
          if (type === 'middle') {
            if (dataArray[i].children && dataArray[i].children.length) {
              if (
                dataArray[i].children[0].departmentId ===
                  result.middleDepartmentId &&
                dataArray[i].children.length > 1
              ) {
                result.nextDepartmentId = dataArray[i].children[1].departmentId
              } else {
                result.nextDepartmentId = dataArray[i].children[0].departmentId
              }
            }
          } else if (type === 'top') {
            // len不可能为0
            // 如果len===1，说明此次循环只有目标元素，没有兄弟节点
            result.nextDepartmentId = targetDepartmentId
            if (len > 1) {
              // 如果排在第一个之前，没有上一个，只有下一个
              if (i === 0) {
              } else if (i === len - 1) {
                result.previousDepartmentId = dataArray[i - 1].departmentId
              } else {
                result.previousDepartmentId = dataArray[i - 1].departmentId
              }
            }
          } else if (type === 'bottom') {
            result.previousDepartmentId = targetDepartmentId

            if (len > 1) {
              if (i === 0) {
                result.nextDepartmentId = dataArray[i + 1].departmentId
              } else if (i === len - 1) {
                // 如果排在最后一个之后，没有下一个，只有上一个
              } else {
                result.nextDepartmentId = dataArray[i + 1].departmentId
              }
            }
          }

          return result
        } else if (dataArray[i].children && dataArray[i].children.length) {
          const result = this.getPostDataByIdAndType(
            dataArray[i].children,
            targetDepartmentId,
            type
          )

          if (result) {
            return result
          }
        }
      }
    },
    // 获取删除后的部门信息和删除部门后的数据
    getDeleteObjAndDataArray(dataArray, departmentId) {
      let dragObj = null
      for (let i = 0, len = dataArray.length; i < len; i++) {
        if (departmentId === dataArray[i].departmentId) {
          dragObj = dataArray.splice(i, 1)
          break
        } else if (dataArray[i].children && dataArray[i].children.length) {
          const result = this.getDeleteObjAndDataArray(
            dataArray[i].children,
            departmentId
          )
          if (result.dragObj) {
            dragObj = result.dragObj
          }
        }
      }

      return {
        dataArray,
        dragObj,
      }
    },
    // 更改父部门更新数据
    updateDataArray(dataArray, pid, dragDepartment) {
      for (let i = 0, len = dataArray.length; i < len; i++) {
        if (pid === '0') {
          // 更新level
          dragDepartment.level = 1
          // 更新pid
          dragDepartment.pid = pid
          // 排序，寻找插入的索引值
          const index = this.getInsertIndex(dataArray, dragDepartment.sort)

          // 添加到父部门下
          dataArray.splice(index, 0, dragDepartment)

          break
        } else {
          if (pid === dataArray[i].departmentId) {
            // 更新level
            dragDepartment.level = dataArray[i].level + 1

            // 更新pid
            dragDepartment.pid = pid

            // 排序，寻找插入的索引值
            const index = this.getInsertIndex(
              dataArray[i].children,
              dragDepartment.sort
            )

            // 添加到父部门下
            dataArray[i].children.splice(index, 0, dragDepartment)

            break
          } else if (dataArray[i].children && dataArray[i].children.length) {
            this.updateDataArray(dataArray[i].children, pid, dragDepartment)
          }
        }
      }

      return dataArray
    },
    // 获取插入的索引
    getInsertIndex(dataArray, sort) {
      let index = 0
      const len = dataArray.length
      for (let i = 0; i < len; i++) {
        if (dataArray[i].sort > sort) {
          index = i + 1
        }
      }
      return index
    },
    // 删除
    async handleDel(departmentId) {
      const res = await this.$api.departmentDelete({
        departmentId,
      })

      if (res === null) {
        this.$message.success('删除成功')

        // 更新数据源
        const { dataArray } = this.getDeleteObjAndDataArray(
          _.cloneDeep(this.$store.state.organize.dataArray),
          departmentId
        )
        this.$store.commit('SET_DATA_ARRAY', dataArray)
      }
    },
    // 编辑
    handleEdit(item) {
      this.$emit('handleEdit', item)
    },
    // 折叠
    foldExpand(item, bool) {
      item.showChildren = bool
    },
    // 隐藏拖拽部门的子部门
    hiddenDragElementChild(dataArray, departmentId) {
      for (let i = 0, len = dataArray.length; i < len; i++) {
        if (departmentId === dataArray[i].departmentId) {
          this.foldExpand(dataArray[i], false)
        } else if (dataArray[i].children && dataArray[i].children.length > 0) {
          this.hiddenDragElementChild(dataArray[i].children, departmentId)
        }
      }
      this.$store.commit('SET_DATA_ARRAY', dataArray)
    },
    // 根据class名更改className属性
    changeClassNameByClass(findClass, newClassName) {
      const list = document.querySelectorAll(findClass)
      if (list.length) {
        for (const element of list) {
          element.className = newClassName
        }
      }
    },
    // 清除索引高亮行和显示的内容
    clearDropBoxAll() {
      const list = document.querySelectorAll('.dropbox')
      for (const element of list) {
        element.innerHTML = ''
        element.style.backgroundColor = 'rgba(44, 160, 189, 0)'
      }
    },
    // 设置高亮并显示内容
    setHightLightAndInnerHTML(target, text) {
      // 先全部清空
      this.clearDropBoxAll()

      target.innerHTML = text

      target.style.backgroundColor = 'rgba(44, 160, 189, 0.3)'
    },
  },
}
